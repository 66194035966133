import React, { useRef, useCallback, useState, Suspense, useEffect } from 'react'
import { Canvas, useThree, useFrame, useLoader } from 'react-three-fiber' 
import { Physics, usePlane, useBox, useCylinder } from '@react-three/cannon'
import { lerp } from '../../../helpers'
import uuid from 'short-uuid'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

const URL = './models/clean-logo.glb'

function Text() {
  const { scene } = useLoader(GLTFLoader, './models/text.glb')

  return (
    <group scale={[2, 2, 2]}>
      <primitive object={scene} /> 
    </group>
  )
}

function Model({yPos, mouse}) {
  const { size, viewport } = useThree()
  const object = useLoader(GLTFLoader, URL)

  // const box = useMemo(() => new THREE.Box3().setFromObject(object.scene), [object.scene])

  const aspect = {
    x: size.width / viewport.width,
    y: size.height / viewport.height
  }

  const [cylinder] = useCylinder(() => ({
    mass: 1,
    position: [mouse.current[0] / aspect.x, yPos, mouse.current[1] / aspect.y],
    args: [1, 1, 1, 64],
  }))

  useEffect(() => {
    setTimeout(() => cylinder.current.visible = true, 100)
  }, [cylinder])

  return (
    <mesh
      ref={cylinder}
      castShadow
      visible={false}
      // material={object.nodes['Logo-3D'].material}
      material={object.materials['Material-gummy']}
      geometry={object.nodes['Logo-3D'].geometry}
      scale={object.nodes['Logo-3D'].scale}
    >
    </mesh>
    // <instancedMesh receiveShadow castShadow ref={cup} args={[null, null, number]}>
    //   <cylinderBufferGeometry attach="geometry"/>
    //   <meshLambertMaterial attach="material" color="#D0011B" />
    // </instancedMesh>
  )
}

function Plane(props) {
  const [ref] = usePlane(() => ({ rotation: [-Math.PI / 2, 0, 0], ...props }))
  const { viewport } = useThree()

  return (
    <mesh ref={ref} receiveShadow>
      <planeBufferGeometry attach="geometry" color="red" args={[viewport.width, viewport.height]} />
      <shadowMaterial attach="material" color="#171717" opacity={0.3}/>
    </mesh>
  )
}

function Cursor({mouse}) {
  const { size, viewport } = useThree()
  const aspect = {
    x: size.width / viewport.width,
    y: size.height / viewport.height
  }

  const [ref, api] = useBox(() => ({ 
    mass: 1,
    position: [0, 1, 0]
  }))

  useFrame(() => {
    const x = lerp(ref.current.position.x, mouse.current[0] / aspect.x, 0.2)
    const y = lerp(ref.current.position.z, mouse.current[1] / aspect.y, 0.2)

    if (ref.current) {
      api.position.set(x, 0, y)
    }
  })

  return (
    <mesh ref={ref}>
      <boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
      <meshStandardMaterial attach="material" color="blue" opacity={0} transparent={true} />
    </mesh>
  )
}

// function Box({ position, mouse }) {
//   const { size, viewport } = useThree()
//   const aspect = {
//     x: size.width / viewport.width,
//     y: size.height / viewport.height
//   }

//   const [ref] = useBox(() => ({ 
//     mass: 1, 
//     rotation: [0.4, 0.2, 0.5],
//     position: [mouse.current[0] / aspect.x, position[1], mouse.current[1] / aspect.y]
//   }))
  
//   return (
//     <mesh ref={ref} castShadow receiveShadow>
//       <boxGeometry attach="geometry" args={[1, 1, 1]} />
//       <meshStandardMaterial attach="material" color="green" />
//     </mesh>
//   )
// }

function InstancedBoxes({ number = 100 }) {
  const max = 1
  const min = -1
  const object = useLoader(GLTFLoader, URL)

  // const [ref] = useBox(index => ({ 
  //   mass: 1, 
  //   rotation: [0.4, 0.2, 0.5], 
  //   position: [(Math.floor(Math.random() * (max - min + 1) ) + min) - 0.5, index * 2, (Math.floor(Math.random() * (max - min + 1) ) + min) - 0.5],
  // }))

  const [cylinder] = useCylinder(index => ({
    mass: 1,
    position: [(Math.floor(Math.random() * (max - min + 1) ) + min) - 0.5, index * 2, (Math.floor(Math.random() * (max - min + 1) ) + min) - 0.5],
    args: [1, 1, 1, 64],
  }))

  return (
    // <instancedMesh receiveShadow castShadow ref={cylinder} args={[null, null, number]}>
    //   <cylinderBufferGeometry attach="geometry"/>
    //   <meshLambertMaterial attach="material" color="#D0011B" />
    // </instancedMesh>

    // Model
    <instancedMesh 
      ref={cylinder} 
      castShadow 
      // material={object.nodes['Logo-3D'].material}
      // material={object.materials['Material-gummy']}
      // geometry={object.nodes['Logo-3D'].geometry}
      // args={[object.nodes['Logo-3D'].geometry, object.nodes['Logo-3D'].material, number]}
      args={[null, null, number]}
      // scale={object.nodes['Logo-3D'].scale}
      >
      <bufferGeometry attach="geometry" {...object.nodes['Logo-3D'].geometry}/>
      <meshStandardMaterial attach="material" {...object.nodes['Logo-3D'].material} />
    </instancedMesh>
  )
}

const ComingSoon = () => {
  const raf = useRef(null)
  const mouse = useRef([0, 0])
  const [boxes, setBoxes] = useState([])
  const [isDragging, setIsDragging] = useState(false)
  const onMouseMove = useCallback(({ clientX: x, clientY: y }) => (mouse.current = [x - window.innerWidth / 2, y - window.innerHeight / 2]), [])
  // const onClick = useCallback(() => setBoxes(boxes => [...boxes, uuid.generate()]), [])
  const threshold = 150

  // High default value so distance is always superior to threshold on first mouseDown
  let lastMousePosition = {
    x: 1000,
    y: 1000
  }

  const onMouseDown = () => {
    console.log(
      '%c ON MOUSE DOWN ', 'background: #30ab50; color: #fff; padding: 4px; border-radius: 5px;')
    setIsDragging(true)
  }

  const onMouseUp = () => {
    console.log('%c ON MOUSE UP ', 'background: #30ab50; color: #fff; padding: 4px; border-radius: 5px;')
    setIsDragging(false)
  }

  const loop = () => {
    console.log('%c LOOP ', 'background: #ba3030; color: #fff; padding: 4px; border-radius: 5px;')

    const mousePosition = {
      x: mouse.current[0],
      y: mouse.current[1]
    }
    
    const distance = Math.hypot(lastMousePosition.x - mousePosition.x, lastMousePosition.y - mousePosition.y)

    // console.log('----- DISTANCE : ' + distance + ' -----')

    if (distance > threshold) {
      setBoxes(boxes => [...boxes, uuid.generate()])
      lastMousePosition = mousePosition
    }

    raf.current = requestAnimationFrame(loop)
  }

  useEffect(() => {
    if (!isDragging) return

    raf.current = requestAnimationFrame(loop)

    return () => cancelAnimationFrame(raf.current)
  }, [isDragging])

  return (
    <>
      <Canvas
        shadowMap 
        sRGB 
        gl={{ alpha: false }} 
        camera={{ position: [0, 10, 0] }}
        onMouseMove={onMouseMove}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        // onClick={onClick}
        tabIndex={0}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          height: '100vh',
          zIndex: 1,
          transform: 'translateZ(0)',
        }}
      >
      <color attach="background" args={['white']} />
      <Suspense fallback={'Loading..'}>
        <hemisphereLight intensity={0.35} />
        <spotLight position={[0, 15, 0]} angle={1} penumbra={1} intensity={1} castShadow />
        <Text/>
        <Physics gravity={[0, -30, 0]}>
          <Plane/>
          <Cursor mouse={mouse}/>
          {boxes.map((index) => (
            <Model key={index} yPos={12} mouse={mouse} />
          ))}
          <InstancedBoxes number={10} />
        </Physics>
      </Suspense>
    </Canvas>
  </>
  )
}

export default ComingSoon