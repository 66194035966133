import React from 'react'
import PropTypes from 'prop-types'
import { setConfiguration } from 'react-grid-system'
import { ThemeProvider } from 'styled-components'
import GlobalStyles from '../components/ui/GlobalStyles'
import GlobalVariables from '../components/ui/GlobalVariables'
// import SmoothScroll from '../components/three/scene/SmoothScroll'
// import config from '../components/three/scene/config'
// import { useViewportScroll } from 'framer-motion'

setConfiguration({
  breakpoints: [768, 1024, 1200, 1400, 1600],
  containerWidths: [720, 960, 1140, 1200, 1550],
  gridColumns: 12
})

const Layout = (props) => {
	// const isSmoothScrollEnabled = true
	// const { scrollY } = useViewportScroll()

	return (
		<>
			<ThemeProvider theme={GlobalVariables}>
				<GlobalStyles/>
					{/* <SmoothScroll
						disabled={!isSmoothScrollEnabled}
						lerp={config.scrollLerp}
						restDelta={config.scrollRestDelta}
						scrollY={scrollY}
					> */}
						{/* {bind => ( */}
							<main className="main">
								{props.children}
							</main>
						{/* )} */}
					{/* </SmoothScroll> */}
			</ThemeProvider>
		</>
	)
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
