import React, { useEffect, useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import config from './config'
import useCanvasStore from './store'
import GlobalRenderer from './GlobalRenderer'
import { Canvas, useFrame, useThree } from 'react-three-fiber'
// import { ResizeObserver } from '@juggle/resize-observer
// import queryString from 'query-string'
// import PerformanceMonitor from './PerformanceMonitor'
// import StatsDebug from './StatsDebug'
// import ResizeManager from './ResizeManager'

import CanvasErrorBoundary from './CanvasErrorBoundary'

function Camera(props) {
  const ref = useRef()
  const { setDefaultCamera } = useThree()

  // Make the camera known to the system
  useEffect(() => {
    setDefaultCamera(ref.current)
  })

  // Update it every frame
  useFrame(() => ref.current.updateMatrixWorld())

  return <perspectiveCamera ref={ref} {...props} aspect={window.innerWidth / window.innerHeight} near={1} far={1000} fov={(180 * (2 * Math.atan(window.innerHeight / 2 / 500))) / Math.PI} />

}

export const GlobalCanvas = ({ children, gl, ...props }) => {
  const pixelRatio = useCanvasStore(state => state.pixelRatio)

  // useEffect(() => {
  //   const qs = queryString.parse(window.location.search)

  //   // show FPS counter?
  //   if (typeof qs.fps !== 'undefined') {
  //     const script = document.createElement('script')
  //     script.onload = function() {
  //       // eslint-disable-next-line no-undef
  //       let stats = new Stats()
  //       document.body.appendChild(stats.dom)
  //       window.requestAnimationFrame(function loop() {
  //         stats.update()
  //         window.requestAnimationFrame(loop)
  //       })
  //     }
  //     script.src = '//mrdoob.github.io/stats.js/build/stats.min.js'
  //     document.head.appendChild(script)
  //   }

  //   // show debug statements
  //   if (typeof qs.debug !== 'undefined') {
  //     config.debug = true
  //   }
  // }, [])

  return (
    <Canvas
      className="canvas"
      invalidateFrameloop={true}
      gl={{
        antialias: false,
        alpha: true,
        stencil: false,
        depth: false,
        powerPreference: 'high-performance',
        // https://blog.tojicode.com/2013/12/failifmajorperformancecaveat-with-great.html
        failIfMajorPerformanceCaveat: true, // skip webgl if slow device
        preserveDrawingBuffer: false,
        premultipliedAlpha: true, // if false, shader antialias becomes a grey-isch outline
        ...gl,
      }}
      colorManagement={true}
      noEvents={true}
      // resize={{ scroll: false, debounce: 0, polyfill: ResizeObserver }}
      resize={{ scroll: false, debounce: 0 }}
      // concurrent // zustand (state mngr) is not compatible with concurrent mode yet
      // orthographic
      // gl2={false}
      pixelRatio={pixelRatio}
      // camera={{ position: [0, 0, 10], fov: 50}}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        height: '100vh', // use 100vh to avoid resize on iOS when url bar goes away
        zIndex: 1, // to sit on top of the page-transition-links styles
        pointerEvents: 'none',
        transform: 'translateZ(0)',
      }}
    >
      <ambientLight />
      <Camera position={[0, 0, 500]} />
      <meshStandardMaterial color="blue" />
      
      <GlobalRenderer>
        {children}
      </GlobalRenderer>
      {/* {config.debug && <StatsDebug />} */}
      {/* <PerformanceMonitor /> */}
      {/* <ResizeManager /> */}
    </Canvas>
  )
}

GlobalCanvas.propTypes = {
  gl: PropTypes.object,
}

const GlobalCanvasIfSupported = ({ onError, ...props }) => {
  const portalEl = useRef()
  const setCanvasAvailable = useCanvasStore(state => state.setCanvasAvailable)

  useLayoutEffect(() => {
    document.documentElement.classList.add('js-has-global-canvas')
  }, [])

  useEffect(() => {
    config.portalEl = portalEl.current
  }, [portalEl])

  return (
    <CanvasErrorBoundary
      onError={err => {
        onError && onError(err)
        setCanvasAvailable(false) /* WebGL failed to init */
        document.documentElement.classList.remove('js-has-global-canvas')
      }}
    >
      <GlobalCanvas {...props} />
      <div ref={portalEl}></div>
    </CanvasErrorBoundary>
  )
}

GlobalCanvasIfSupported.propTypes = {
  onError: PropTypes.func,
}

export default GlobalCanvasIfSupported
