import React, { Suspense } from 'react'
import { GlobalCanvas } from './src/components/three/scene/GlobalCanvas'
import "./src/assets/styles/main.scss"
import ComingSoon from './src/components/three/coming-soon';

const { registerLinkResolver } = require('gatsby-source-prismic-graphql');
const { linkResolver } = require('./src/components/utils/linkResolver');

registerLinkResolver(linkResolver);

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
    return (
      <>
		    <Suspense fallback={<h1>Chargement...</h1>}>
          {element}
          <GlobalCanvas>
          </GlobalCanvas>
          <ComingSoon/>
		    </Suspense>
      </>
    )
  }