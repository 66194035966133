// Use to override Frustum temporarily to pre-upload textures to GPU
function setAllCulled(obj, overrideCulled) {
  if (!obj) return
  if (overrideCulled === false) {
    obj.wasFrustumCulled = obj.frustumCulled
    obj.wasVisible = obj.visible
    obj.visible = true
    obj.frustumCulled = false
  } else {
    obj.visible = obj.wasVisible
    obj.frustumCulled = obj.wasFrustumCulled
  }
  obj.children.forEach(child => setAllCulled(child, overrideCulled))
}

export {
  setAllCulled
}
